@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItem_slot {
  align-items: center;
}

.removeSlot {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 5px;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding-bottom: 6px; */

  @media (--viewportMedium) {
    /* padding-bottom: 8px; */
  }
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dayLabel {
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.dayInfo,
.totalPrice {
  /* composes: p from global; */
  composes: textSmall from global;
  /* font-weight: var(--fontWeightBold); */
  letter-spacing: -0.2px;

  margin: 0;
  padding: 0;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  composes: marketplaceSmallFontStyles from global;
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.listHeading {
  text-align: center;
}

.coupon_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.remove_btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: red;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.coupon_form_wrapper {
  margin-top: 7px;
}

.coupon_form_wrapper form {
  display: flex;
  align-items: center;
}

.coupon_form_wrapper form input {
  flex: 2;
  height: 40px;
  min-height: 40px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.coupon_form_wrapper form button {
  height: 40px;
  min-height: 40px;
  flex: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.error_wrapper {
  display: flex;
  margin-block-start: 10px;
  padding: 10px 20px;
  background: #fff1da;
}

.coupon_error {
  display: inline-block;
  margin-inline-start: 10px;
  color: #ec980c;
  font-size: 14px;
  text-transform: capitalize;
}
