@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--colorBlack);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;

}

.checkboxWrapper {
  height: 32px;
  margin-top: -1px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
  }
}

.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.modalFilters .textRoot {
  width: 100%;
  padding: 4px;
}

.modalFilters .textRoot:hover {
  color: white !important;
}

.modalFilters .label {
  justify-content: center;
  cursor: pointer;
  align-items: center;
  text-align: center;
}

.modalFilters .checkboxWrapper {
  display: none;
}

.slider {
  /* position: absolute; */
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  width: 36px;
  height: 20px;
  padding: 2px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.slider.checked {
  background-color: var(--marketplaceColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--marketplaceColor);
}
.slider.checked:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
