.form_wrapper {
  background-color: lightblue;
  font-family: 'Poppins';
}

.field_wrapper {
  margin-bottom: 20px;
}

.field_wrapper label {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #4a4a4f;
}

.field_wrapper input {
  font-family: 'Poppins';
  width: 100%;
  min-height: 48px;
  border: 1px solid #d8dce6;
  border-radius: 6px;
}

.field_wrapper textarea {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.submit_button {
  width: 100%;
}

.submit_button button {
  font-family: 'Poppins';
  width: 100%;
  min-height: 48px;
  background: #f4cc8f;
  border: 0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
